import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Typography from '../../../primitives/typography';
import { colors } from '../../../primitives/colors';
import CascadeChildren from '../../../animations/transitions/cascade-children';
import Check from './check.svg';
import { breakpoints } from '../../../primitives/tokens';
import { getLink } from '../../../primitives/link';
import { StyledButtonAsLink } from '../../../primitives/styled-button';

export type PricingProps = Queries.PricingPropsFragment;

const Pricing = ({
    richTitle,
    items,
    link,
}: PricingProps): JSX.Element => (
    <Container css={{
        marginTop: '140px',
        marginBottom: '80px',
        [breakpoints.mb]: {
            marginTop: '90px',
            marginBottom: '64px',
        },
    }}
    >
        <Row>
            <Col
                breakpoints={{
                    dt: { span: 8, start: 2 },
                    tb: { span: 12, start: 0 },
                    mb: { span: 4, start: 0 },
                }}
            >
                <Typography
                    fontSize={{
                        dt: 4053,
                        tb: 3234,
                        mb: 2429,
                    }}
                    css={{
                        maxWidth: '480px',
                        margin: 'auto',
                        textAlign: 'center',
                    }}
                    override
                    fontWeight="light"
                >
                    <CustomStructuredTextRenderer data={richTitle} />
                </Typography>
                <CascadeChildren
                    as="ul"
                    css={{
                        marginTop: '80px',
                        gap: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        listStyleType: 'none',
                        [breakpoints.mb]: {
                            marginTop: '56px',
                        },
                    }}
                >
                    {items && items.map(item => (
                        <li css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${colors.grey100}`,
                            paddingBottom: '30px',
                        }}
                        >
                            <div css={{
                                paddingRight: '12px',
                            }}
                            >
                                <Typography
                                    fontSize={{
                                        dt: 1822,
                                    }}
                                    color="primaryOrange"
                                    fontWeight="medium"
                                >
                                    {item.title}
                                </Typography>
                                <Typography
                                    fontSize={{
                                        dt: 1420,
                                    }}
                                    css={{
                                        marginTop: '8px',
                                    }}
                                >
                                    {item.text}
                                </Typography>
                            </div>
                            <Check css={{
                                flex: 'none',
                            }}
                            />
                        </li>
                    ))}
                </CascadeChildren>
                {(link && link.length > 0) && (
                    <div css={{
                        paddingTop: '52px',
                        margin: 'auto',
                        textAlign: 'center',
                        [breakpoints.mb]: {
                            paddingTop: '32px',
                        },
                    }}
                    >
                        <StyledButtonAsLink
                            to={getLink(link[0].link)}
                        >
                            {link[0].title}
                        </StyledButtonAsLink>
                    </div>
                )}
            </Col>
        </Row>
    </Container>
);

export default Pricing;

export const query = graphql`
    fragment PricingProps on DatoCmsPricing {
        richTitle {
            blocks
            links
            value
        }
        items {
            title
            text
        }
        link {
            ...LinkWithTitleProps
        }
    }
`;
